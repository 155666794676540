<template>
  <section>  
    <b-row class="match-height">
      <div class="col-md-12 col-lg-12">
        <div class="card">
          <div class="card-header border-bottom">
            <h4 class="card-title">
              Events ({{ totalCount }})
            </h4>
            <form>
              <div class="form-group">
                <input
                  v-model="searchParam"
                  name="search"
                  placeholder="Search"
                  class="form-control"
                >
              </div>
            </form>
            <b-button
              v-b-modal.event-modal
              variant="primary"
            >
              Create Event
            </b-button>
          </div>
          <div class="card-datatable mb-2">
            <div
              v-if="dataLoading"
              class="overlay"
            >
              Please wait ....
            </div>            
            <table v-else class="datatables-ajax table table-responsive w-100 ">
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Cover</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th>SN</th>
                  <th>Cover</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </tfoot>
              <tbody>
                <tr
                  v-for="(event, index) in eventList"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    <b-img 
                      :src="event.cover_photo"
                      width="200"
                    />
                  </td>
                  <td class="event-title">
                    {{ event.title }}
                  </td>
                  <td class="event-title" v-html="event.body.substring(0, 50)+'....'"></td>
                  <td>{{ event.start_date }}</td>
                  <td>{{ event.end_date }}</td>
                  <td>{{ event.status }}</td>
                  <td>
                    <b-button-group>
                      <router-link
                        :to="'/dashboard/events/'+event.id"
                        v-b-modal.event-edit-modal
                        class="btn btn-primary btn-sm"
                        size="sm"
                        @click="selectEvent(event)"
                      >
                      <feather-icon
                        class="mr-25"
                        icon="EyeIcon"
                        size="15"
                      />
                      </router-link>
                      <b-button
                        v-b-modal.event-edit-modal
                        variant="warning"
                        size="sm"
                        @click="selectEvent(event)"
                      >
                      <feather-icon
                        class="mr-25"
                        icon="EditIcon"
                        size="15"
                      />
                      </b-button>
                      <b-button 
                        variant="danger" 
                        size="sm"
                        @click="deleteEvent(event)"
                      >
                      <feather-icon
                        class="mr-25"
                        icon="DeleteIcon"
                        size="15"
                      />
                      </b-button>
                    </b-button-group>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <nav aria-label="Page navigation" class="ml-2">
            <ul class="pagination">
              <li :class="link.active ? 'page-item active' : 'page-item'" v-for="link in links" :key="link.id">
                <a class="page-link" href="javascript:;" @click="paginate(link.url)" v-html="link.label"></a>
              </li>
            </ul>
          </nav>     
        </div>
      </div>
    </b-row> 
    <b-modal
      id="event-modal"
      content-class="shadow"
      title="Create New Event"
      centered
      size="lg"
      no-close-on-backdrop
      hide-footer
    >
      <b-form>
        <b-row>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Title"
            >
              <b-form-input
                type="text"
                name="title"
                aria-label="Title"
                v-model="details.title"
                placeholder="Title"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Type"
            >
              <b-form-input
                type="text"
                name="type"
                aria-label="Type"
                v-model="details.type"
                placeholder="Type"
              />
            </b-form-group>
          </b-col>          
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Start Date"
            >
              <b-form-datepicker
                name="start_date"
                aria-label="Start Date"
                v-model="details.start_date"
                placeholder="Start Date"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="End Date"
            >
              <b-form-datepicker
                name="end_date"
                aria-label="End Date"
                v-model="details.end_date"
                placeholder="End Date"
              />
            </b-form-group>
          </b-col>
          <b-col lg="12" md="12">
            <b-form-group
              class="text-primary"
              label="Description"
            >
              <ckeditor
                name="body"
                aria-label="Description"
                v-model="details.body"
                placeholder="Description"
              ></ckeditor>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Event Cover"
            >
              <b-form-radio-group
                v-model="details.status"
              >
                <b-form-radio value="Published" /> 
                Publish
                <b-form-radio value="Unpublished" /> 
                Draft
              </b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Event Cover"
            >
              <b-form-file
                @change="prepareFile"
                accept=".jpg, .png, .gif"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-form-group>
          </b-col>
          <b-col md="12" lg="12" class="text-center">
            <b-button variant="primary" size="lg" @click="addEvent">
              Save 
              <b-spinner
                variant="white"
                small
                class="ml-1"
                v-show="loading"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>       
    <b-modal
      id="event-edit-modal"
      content-class="shadow"
      :title="`Edit ${selected.title}`"
      centered
      size="lg"
      no-close-on-backdrop
      hide-footer
    >
      <b-form>
        <b-row>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Title"
            >
              <b-form-input
                type="text"
                name="title"
                aria-label="Title"
                v-model="selected.title"
                placeholder="Title"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Type"
            >
              <b-form-input
                type="text"
                name="type"
                aria-label="Type"
                v-model="selected.type"
                placeholder="Typw"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              :label="`Start Date (${selected.start_date})`"
            >
              <b-form-datepicker
                name="start_date"
                aria-label="Start Date"
                v-model="selected.start_date"
                placeholder="Start Date"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              :label="`End Date (${selected.end_date})`"
            >
              <b-form-datepicker
                name="end_date"
                aria-label="End Date"
                v-model="selected.end_date"
                placeholder="End Date"
              />
            </b-form-group>
          </b-col>
          <b-col lg="12" md="12">
            <b-form-group
              class="text-primary"
              label="Description"
            >
              <ckeditor
                name="body"
                aria-label="Description"
                v-model="selected.body"
                placeholder="Description"
              ></ckeditor>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Event Cover"
            >
              <b-form-radio-group
                v-model="selected.status"
              >
                <b-form-radio value="Published" /> 
                Publish
                <b-form-radio value="Unpublished" /> 
                Draft
              </b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-img 
              :src="selected.cover_photo"
              width="100"
            />
            <b-form-group
              class="text-primary"
              label="Change Event Cover"
            >
              <b-form-file
                @change="prepareFile"
                accept=".jpg, .png, .gif"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-form-group>
          </b-col>
          <b-col md="12" lg="12" class="text-center">
            <b-button variant="primary" size="lg" @click="updateEvent">
              Save 
              <b-spinner
                variant="white"
                small
                class="ml-1"
                v-show="loading"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>       
  </section>
</template>

<script>
import {
  BRow, BMedia, BAvatar, BCard, BCol, BCardBody, 
  BMediaAside, BMediaBody,BCardText, BButtonGroup,
  BButton, BModal, BForm, BFormGroup, BImg, BFormRadioGroup, BFormRadio,
  BFormDatepicker, BFormFile,BFormInput, BFormTextarea, BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BSpinner,
    BImg,
    BFormTextarea,
    BFormRadioGroup,
    BFormRadio,
    BFormInput,
    BFormFile,
    BFormDatepicker,
    BButtonGroup,
    BForm,
    BFormGroup,
    BModal,
    BButton,
    BMedia,
    BAvatar,
    BCard,
    BCol,
    BCardBody, BMediaAside, BMediaBody,BCardText,
  },
  data() {
    return {
      form: new FormData(),
      dataLoading: false,
      details: {},
      selected: {},
      data: {},
      bhvEvents: [],
      meta: {},
      links: {},
      url: '',
      loading: false,
      searchParam: '',
      cancelled_withdrawals: 0,
      failed_withdrawals: 0,
      monthly: 0,
      total_widthrawals: 0,
      totalCount: 0,
    }
  },
  computed: {
    eventList() {
      return this.bhvEvents.filter(item => item.title.includes(this.searchParam) || item.title.toLowerCase().includes(this.searchParam))
    },
  },
  mounted() {
    this.url = this.$url+'/events'
    this.getEvents()
  },
  methods: {
    prepareFile(event) {
      const files = event.target.files || event.dataTransfer.files
      if (!files.length) return
      this.form.append('cover_photo', files[0])
    }, 
    deleteEvent(event) {
      this.$swal({
        title: `Delete ${event.title}`,
        html: '<div class=" text-center mt-4"><h4 class="text-danger mb-3">Are you sure you want to delete this ?</h4></div>',
        confirmButtonText: 'Yes, delete it!',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary text-primary ml-1',
        },        
      }).then(result => {
        if (result.value) {
          this.deleteSelected(event.id)
        } 
      })
    },    
    deleteSelected(id) {
      this.$http.delete(`${this.$url}/events/${id}`)
        .then(response => {
          console.log(response)
          if(response.data.status) {
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            .then(res => {
              window.location.reload()
            })
          }
        })
        .catch( err => {
          if(err.response) {
            let message
            if(err.response.status != 200) {
              if(err.response.data.errors) {
                let errors = err.response.data.errors
                let errorList = Object.values(errors)
                errorList.map(msg => {
                    message = msg
                })
              }
              this.$swal({
                title: 'Error',
                text: err.response.data.message || message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-secondary',
                },
              })  
            }        
          }          

        })
    },     
    selectEvent(item) {
      this.selected = item
    },   
    paginate(url) {
      this.url = url
      this.getEvents()
    },  
    updateEvent() {
      this.loading = true
      this.form.append("title", this.selected.title)
      this.form.append("type", this.selected.type)
      this.form.append("body", this.selected.body)
      this.form.append("status", this.selected.status)
      this.form.append("start_date", this.selected.start_date)
      this.form.append("end_date", this.selected.end_date)
      this.$http.post(`${this.$url}/events/${this.selected.id}`, this.form)
        .then(response => {
          console.log("categories", response.data)
          if(response.data.status) {
            this.selected = response.data.data 
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })            
          }
        })
        .catch(err => console.log(err))
        .finally(() => this.loading = false)
    },        
    addEvent() {
      this.loading = true
      this.form.append("title", this.details.title)
      this.form.append("type", this.details.type)
      this.form.append("body", this.details.body)
      this.form.append("status", this.details.status)
      this.form.append("start_date", this.details.start_date)
      this.form.append("end_date", this.details.end_date)
      this.$http.post(`${this.$url}/events`, this.form)
        .then(response => {
          console.log("categories", response.data)
          if(response.data.status) {
            this.bhvEvents.push(response.data.data) 
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })            
          }
        })
        .catch(err => console.log(err))
        .finally(() => this.loading = false)
    },        
    getEvents() {
      this.dataLoading = true
      this.$http.get(`${this.url}`)
        .then(response => {
          console.log("EVEBTS", response.data)
          if(response.data.status) {
            this.bhvEvents = response.data.data.data
            this.links = response.data.data.links
            this.meta = response.data.data.meta ?? []
            this.totalCount = response.data.data.total
          }
        })
        .finally(() => this.dataLoading = false)
    },
  },
}
</script>

<style lang="scss">
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,.2);
    font-size: 20px;
    color: #fff;
  }
  .event-title {
    text-transform: lowercase;
    width: 50%;
  }
</style>
